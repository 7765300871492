.Bakery {
  background-color: #faedd0;
  min-height: 100vh;

  display: grid;
  grid-template-rows: auto 1fr auto;
}

.BakeryMain {
  background-image: url("../media/gray-floral.png");
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2rem;
  padding: 2rem;
}

.BakeryMainImgBox {
  border: 1px solid black;
  background-color: #F9F6F0;
  overflow: hidden;
}

.BakeryMainImgBox img {
  width: 100%;
  margin: -60px 0;
}

.BakeryMainCustomOrderTextBoxWrapper {
  display:  grid;
  grid-template-rows: auto 30vh 15vh;
  gap: 2rem;
}

.BakeryMainCustomOrderTextBox {
  font-family: shippori_mincho;
  font-weight: 400;
  text-decoration: none;
  color: black;
  font-size: 2rem;
  border: 1px solid black;
  padding: 1rem 3rem 1rem 3rem ;
  background-color: #F9F6F0;
}

.BakeryHeader img {
  height: 15vh;
}

.BakeryFooter {
  border-top: 2px solid black;
  font-family: shippori_mincho;
  text-align:  center;
  padding: 1rem;
  background-image: url("../media/bg.png");
}

.BakeryHeader {
  display: flex;
  padding: 0.5rem 2rem 0.5rem 2rem ;
  border-bottom: 2px solid black;
  justify-content: space-between;
  background-image: url("../media/bg.png");
}

.BakeryHeader nav {
  align-self: center;
}

.BakeryHeader nav ul {
  list-style-type: none;
  display: inline-flex;
}

.BakeryHeader nav ul li {
  padding: 1rem;
}

.BakeryHeader nav ul a {
  font-family: shippori_mincho;
  font-weight: 400;
  text-decoration: none;
  color: black;
  font-size: 1.5rem;
  border: 1px solid black;
  padding: 1rem 3rem 1rem 3rem ;
  background-color: #F9F6F0;
}
