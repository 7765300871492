.HomemadeHome {
  background-color: #faedd0;
  background-image: url("../media/bg.png");
  min-height: 100vh;
  display: grid;
  place-items: center;
}

.HomemadeWrapper h1 {
  margin: 0;
  font-family: shippori_mincho;
  font-weight: 400;
  font-size: 2.5rem;
  text-align: right;
  transform: translateY(-70px);
  display: grid;
}

.HomemadeWrapper a {
  border: 2px solid black;
  background-color: #F9F6F0;
  padding: 1rem 3rem 1rem 3rem ;
  font-size: 2rem;
  font-family: shippori_mincho;
  font-weight: 800;
  text-decoration: none;
  color: black;
}
