.LandingPage {
  background-color: #faedd0;
  background-image: url("./media/bg.png");
  min-height: 100vh;

  display: grid;
  grid-template-rows: auto 1fr auto;
}

.LandingPageHeader {
  display: grid;
  place-items: center;
  padding: 0.5rem;
  border-bottom: 2px solid black;
}

.LandingPageHeader img {
  height: 20vh;
}

.LandingPageMain {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.LandingPageBakery {
    /* Settings for your image */
  background: url('./media/landingpagebakery.jpg');
  background-size: cover;
  background-position: center;
  /* Size info */
  height: 100%;
  width: 100%;
  /* Positioning (important as it dictates how child elements will be positioned) */
  position: relative;
}

.LandingPageHomemade {
    /* Settings for your image */
  background: url('./media/landingpagehomemade.jpg');
  background-size: cover;
  background-position: center;
  /* Size info */
  height: 100%;
  width: 100%;
  /* Positioning (important as it dictates how child elements will be positioned) */
  position: relative;
}

.LandingPageFooter {
  border-top: 2px solid black;
  font-family: shippori_mincho;
  text-align:  center;
  padding: 1rem;
}

.LandingPageFooter b {
  font-family: shippori_mincho;
  font-weight: 800;
}

.LandingPageTextBox {
  display:  grid;
  place-items:  center;
  height: 100%;
}

.LandingPageTextBox a {
  border: 1px solid black;
  background-color: #F9F6F0;
  padding: 1rem 3rem 1rem 3rem ;
  font-size: 2rem;
  font-family: shippori_mincho;
  font-weight: 800;
  text-decoration: none;
  color: black;
}
