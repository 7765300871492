.BakeryInvoice {
  background-color: #faedd0;
  min-height: 100vh;

  display: grid;
  grid-template-rows: auto 1fr auto;
}

.BakeryInvoice h1 {
  font-family: shippori_mincho;
  font-weight: 800;
  font-size: 2.5rem;
}

.BakeryInvoiceMain {
  background-image: url("../media/gray-floral.png");
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
}

.BakeryInvoiceMainHeader {
  padding: 32px;
  grid-column: 1;
}

.BakeryInvoiceMain form {
  width: 95%;
  grid-column: 2;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-family: shippori_mincho;
  font-size: 1.5rem;
}

.BakeryInvoiceMain form .StripeElement {
  border: 2px solid black;
  padding: 1rem 3rem 1rem 3rem;
  background-color: #f9f6f0;
}

.BakeryInvoiceMain form label {
  border: 2px solid black;
  padding: 1rem 3rem 1rem 3rem;
  background-color: #f9f6f0;
}

.BakeryInvoiceMain form button {
  border: 2px solid black;
  padding: 1rem 3rem 1rem 3rem;
  background-color: #f9f6f0;
  font-size: inherit;
}

.BakeryInvoiceMain form label input {
  margin-left: 1rem;
}

.BakeryInvoiceMain form label textarea {
  margin-left: 1rem;
}

.BakeryInvoiceHeader img {
  height: 15vh;
}

.BakeryInvoiceFooter {
  border-top: 2px solid black;
  font-family: shippori_mincho;
  text-align: center;
  padding: 1rem;
  background-image: url("../media/bg.png");
}

.BakeryInvoiceHeader {
  display: flex;
  padding: 0.5rem 2rem 0.5rem 2rem;
  border-bottom: 2px solid black;
  justify-content: space-between;
  background-image: url("../media/bg.png");
}

.BakeryInvoiceHeader nav {
  align-self: center;
}

.BakeryInvoiceHeader nav ul {
  list-style-type: none;
  display: inline-flex;
}

.BakeryInvoiceHeader nav ul li {
  padding: 1rem;
}

.BakeryInvoiceHeader nav ul a {
  font-family: shippori_mincho;
  font-weight: 400;
  text-decoration: none;
  color: black;
  font-size: 1.5rem;
  border: 1px solid black;
  padding: 1rem 3rem 1rem 3rem;
  background-color: #f9f6f0;
}

.BakeryInvoiceCategoriesLabel {
  border: 2px solid black;
  padding: 1rem 3rem 1rem 3rem;
  background-color: #f9f6f0;
}

.BakeryInvoiceCategories label input {
  margin-bottom: 1.5rem;
}

.BakeryInvoiceMessage {
  grid-column: 1 / span 2;
  font-size: 2.5em;
  padding: 16px;
}
